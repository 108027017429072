import React, {useEffect, useState} from 'react'
import "./ColorPicker.css"
import { HexColorPicker } from "react-colorful";
import hexRgb from 'hex-rgb';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {IoCopyOutline} from "react-icons/io5";
import { IconContext } from "react-icons";
import ViewNotif from "./ViewNotif";

var convert = require('color-convert');

const ColorPicker = () => {

    const [color, setColor] = useState("#aabbcc");
    const [rgb, setRgb] = useState("");
    const [hsl, setHsl] = useState("");
    const [update, setUpdate] = useState(null);
    const [current, setCurrent] = useState(1);

    const showCopied = () => {
        if (update == null) setUpdate(false);
        else setUpdate(!update);
    }

    const updateColor = e => {
        if (current === 1) {
            setColor(e.target.value)
            setRgb(convert.hex.rgb(e.target.value));
            setHsl(convert.hex.hsl(e.target.value));
        } else if (current == 2) {
            setRgb(e.target.value)
            let col = (e.target.value).split`,`.map(x=>+x)
            setColor(convert.rgb.hex(col));
            setHsl(convert.rgb.hsl(col));
        } else  if (current == 3) {
            setHsl(e.target.value);
            console.log(e.target.value)
            let col = (e.target.value).split`,`.map(x=>+x);
            setColor(convert.hsl.hex(col));
            setRgb(convert.hsl.rgb(col));

        }
    }

    useEffect(() => {
        if (current === 1) {
            setRgb(convert.hex.rgb(color));
            setHsl(convert.hex.hsl(color));
        }
    }, [color])

    return (
        <div className="CP-container">
            {update != null && <ViewNotif className={"CP-viewnotif"} update={update} note={"Copied"}/>}
            <div className="CP-inner">
                <section className="resposive example">
                    <HexColorPicker color={color} onChange={(setColor)}/>
                </section>        
                <div className="CP-info">    
                    <div className="CP-row-container">
                        <div className="CP-title">HEX</div>
                        <div className="CP-row">
                            <input
                                className="CP-row-input" 
                                value={color}
                                maxLength={7}
                                onClick={() => setCurrent(1)}
                                onChange={updateColor}

                            />
                        </div>
                        
                            <CopyToClipboard text={color}>
                                <button 
                                    className="CP-copy-btn"
                                    onClick={showCopied}
                                >
                                    <IconContext.Provider value={{className: "CP-icon-style"}}>
                                        <IoCopyOutline/>
                                    </IconContext.Provider>
                                </button>
                            </CopyToClipboard>
                    </div>
                    <div className="CP-row-container">
                        <div className="CP-title">RGB</div>
                        <div className="CP-row">
                            <input
                                className="CP-row-input" 
                                value={rgb}
                                onClick={() => setCurrent(2)}
                                onChange={updateColor}
                                maxLength={11}
                            />
                        </div> 
                        <CopyToClipboard text={rgb}>
                                <button 
                                    className="CP-copy-btn"
                                    onClick={() => setUpdate(!update)}
                                >
                                    <IconContext.Provider value={{className: "CP-icon-style"}}>
                                        <IoCopyOutline/>
                                    </IconContext.Provider>
                                </button>
                            </CopyToClipboard>  
                    </div><div className="CP-row-container">
                        <div className="CP-title">HSL</div>
                        <div className="CP-row">
                            <input
                                className="CP-row-input" 
                                value={hsl}
                                onClick={() => setCurrent(3)}
                                onChange={updateColor}
                                maxLength={11}

                            />
                        </div>  
                        <CopyToClipboard text={hsl}>
                                <button 
                                    className="CP-copy-btn"
                                    onClick={() => setUpdate(!update)}
                                >
                                    <IconContext.Provider value={{className: "CP-icon-style"}}>
                                        <IoCopyOutline/>
                                    </IconContext.Provider>
                                </button>
                            </CopyToClipboard> 
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default ColorPicker
