import React, {useState, useEffect, useRef} from 'react'
import "./NotesView.css"
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { RiSettingsLine } from 'react-icons/ri';
import { IconContext } from "react-icons";
import NoteRow from './NoteRow';
import {IoIosAdd} from 'react-icons/io'
import NoteOverlay from './NoteOverlay';
import {db} from "../fire";
import firebase from 'firebase/app'

const NotesView = ({idx}) => {

    const [notes, setNotes] = useState([]);
    const [overlay, setOverlay] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newBody, setNewBody] = useState("");
    const [click, setClick] = useState(false);

    const ref = useRef();

    //fetching list of notes
    useEffect(() => {        
        db.collection("userData")
        .doc(firebase.auth().currentUser.uid)
        .collection("appNotes")
        .doc(`appNotes${idx}`).get().then(snapshot => {

            if (snapshot.exists) {
                let arr = []
                snapshot.data().notes.forEach(note => {
                    arr.push(note);
                })
                setNotes(arr);
            }
        })
    },[])

    const updateNotesDb = () => {
        db.collection("userData")
        .doc(firebase.auth().currentUser.uid)
        .collection("appNotes")
        .doc(`appNotes${idx}`)
        .set({notes: notes}, {merge: true})
    }
    const updateNotes = (idx) => {
        let arr = notes;
        arr[idx] = null;
        setNotes(arr);
        setNotes(notes.filter(note => note != null));
        updateNotesDb();
    }

    useEffect(() => {
        if (notes.length <= 0) return;
        updateNotesDb();
    }, [newTitle, newBody])

    useEffect(() => {
        setTimeout(() => {
            setClick(false);
        }, 500)
    }, [click])

    const newNote = () => {
        setOverlay(true);
        setClick(true);
    }

    const closeNewNote = e => {
        if (click) return;

        if (overlay && !JSON.stringify(e.target.className).includes("NoteOverlay")) {
            if (newBody !== "" || newTitle !== "") {
                setNotes(oldArray => [{title: newTitle, body: newBody},...oldArray]);
            }
            setOverlay(false);
        }
    }

    const openNote = (title, body, idx) => {
        setClick(true);
        setOverlay(true);
        setNewTitle(title);
        setNewBody(body);
        
        setTimeout(() => {
            updateNotes(idx);
        }, 500);
    }

    const deleteNote = (idx) => {
        updateNotes(idx);
    }

    

    return (
        <>
        <Flippy
            flipOnHover={false} // default false
            flipOnClick={false} // default false
            flipDirection="horizontal" // horizontal or vertical
            ref={ref} // to use toggle method like ref.curret.toggle()
        >
        
        <FrontSide
            onClick={closeNewNote}
        >
            <NoteOverlay 
                show={overlay}
                updateTitle={t => setNewTitle(t)}
                updateBody={t => setNewBody(t)}
                t={newTitle}
                b={newBody}

            />
            <div className="flip-btn-box">
                <button className="flip-btn nv" onClick={() => { ref.current.toggle(); }}>
                    <IconContext.Provider value={{ color: "#ffe37f", className: "icon-style"}}>
                        <RiSettingsLine/>
                    </IconContext.Provider>
                </button>
            </div>

            <div className={"nv-notes-tile"}>
                <div 
                    className={"nv-add-container"}
                >
                    <div 
                        className={"nv-add-btn"}
                        onClick={newNote}
                    >
                        <IconContext.Provider value={{className: "nv-icon-style"}}>
                            <IoIosAdd/>
                        </IconContext.Provider>
                    </div>

                    <div className="nv-note-count">{notes.length} Notes</div>
                </div>
                <div className={"nv-notes-container"}>
                {
                    notes.map((noteInfo, idx) =>
                    <NoteRow 
                        key={idx} 
                        title={noteInfo.title}
                        body={noteInfo.body}
                        select={() => openNote(noteInfo.title, noteInfo.body, idx)}
                        deleteNote={() => deleteNote(idx)}
                    />)
                }
                
                </div>
            </div>

        </FrontSide>
        
        <BackSide>
            <div className="flip-btn-box">
                <div className="flip-btn nv" onClick={() => { ref.current.toggle(); }}>
                    <IconContext.Provider value={{ color: "#ffe37f", className: "icon-style"}}>
                        <RiSettingsLine/>
                    </IconContext.Provider>
                    </div>
            </div>

            <div className={"nv-notes-tile"}>

            </div>

        </BackSide>

    </Flippy>
        </>
    )
}

export default NotesView
