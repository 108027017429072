import React from 'react'
import styled from 'styled-components'

const HabitRow = () => {

    return (
        <Container>
            <Details>
                <Title>View Construction in Progress</Title>
            </Details>
        </Container>
    )
}

export default HabitRow

const Container = styled.div`
    width: 95%;
    height: 50px;
    margin: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    display: flex;
`

const Details = styled.div`
    width: 100%;
    height: 100%;
    background-color: beige;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const Title = styled.div`
    margin: 10px;
    overflow: hidden;

`