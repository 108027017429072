import React, {useState} from 'react';
import './Login.css';
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai"
import { IconContext } from "react-icons";
import {FcGoogle} from "react-icons/fc"
import firebase from 'firebase/app'



const Login = ({email, password, setEmail, setPassword, handleLogin, handleSignup, hasAcc, setHasAcc, errorMsg, googleSignIn}) => {
    
    const height = (window.screen.height - (window.outerHeight - window.innerHeight));

    const [view, setView] = useState(false);
    const [error, setError] = useState("")
    const [resetPass, setResetPass] = useState(false)

    const handleEnter = e => {
        if (e.code === "Enter") {
            if (hasAcc) {
                handleLogin();
            } else {
                handleSignup();
            }
        }
    }

    /*
    <div className="sign-in-with">
                    or sign in with
                    <div className="sign-in-option-box" onClick={googleSignIn}>
                        <IconContext.Provider 
                            value={{ className: "login-sso-icon"}}>
                            <FcGoogle/> 
                        </IconContext.Provider> 
                    </div>
                </div>
     */           

    const sendResetEmail = e => {
        firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            setError("Password reset email sent");
        })
        .catch(error => {
            if (error.code === "auth/invalid-email") setError("Invalid email address");
            else if (error.code === "auth/user-not-found") setError("Email address not linked to account");
            else console.log(error);
        })
        setEmail("");
    }

    const toggleResetPass = () => {
        setResetPass(!resetPass);
    }

    return (
        <div className="login-outside-container" style={{height: `${height}px`}}>
            {resetPass &&
                    <div className="reset-pass-box">
                        {error !== "" && <div className="reset-err">{error}</div>}
                        <div className="rpb-text">Please enter your email address below to have a password reset link sent.</div>
                        <input 
                        tabIndex="1"
                        className="login-input"
                        type="text" 
                        autoFocus 
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        autoComplete="new-password"
                    />
                    <button className="login-btn" style={{marginTop:"10px"}} onClick={sendResetEmail}>Send reset link</button>
                    <div className="reset-back" onClick={toggleResetPass}>back</div>
                    </div>
                    
                }
            
            {!resetPass && <div className="login-container">
                <div className="login-icon-container">morning.</div>
                
                
                <div className="login-error-msg">{errorMsg}</div>
                <form
                
                    type="button"
                >    
                    <input 
                        tabIndex="1"
                        onKeyDown={handleEnter}
                        className="login-input"
                        type="text" 
                        autoFocus 
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        autoComplete="email"
                    />
                    <div className="login-password-container">
                        <input
                            tabIndex="1"
                            onKeyDown={handleEnter}
                            className="login-input"
                            type={!view ? "password" : "text"}
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            autoComplete={hasAcc ? "current-password" : "new-password"}
                        />
                        <IconContext.Provider 
                            value={{ className: "login-view-icon"}}>
                            {view ? 
                                <AiOutlineEye onClick={() => setView(!view)}/> 
                                : <AiOutlineEyeInvisible onClick={() => setView(!view)}/>}
                        </IconContext.Provider>
                    </div>
                </form>

                <div className="login-btn-container">
                    {hasAcc ? (
                        <>
                        <button className="login-btn" onClick={handleLogin}>Sign in</button>
                        <div className="login-switch">Don't have an account? <span className={"login-span"} onClick={() => setHasAcc(!hasAcc)}> Sign up</span></div>
                        </>
                    ) : (
                        <>
                        <button className="login-btn" onClick={handleSignup}>Sign up</button>
                        <div className="login-switch">Have an account?<span className={"login-span"} onClick={() => setHasAcc(!hasAcc)}> Sign in</span></div>
                        </>
                    )}
                </div>

                
                <div className="reset-pass-text" onClick={toggleResetPass}>Forgot your password?</div>
            </div>}

        </div>
    )
}

export default Login