import React from 'react'
import "./Habit.css"
import HabitRow from "./HabitRow.js"

const Habit = () => {
    return (
        <div className="H-container">
            <HabitRow />
        </div>
    )
}

export default Habit
