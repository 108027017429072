import React from 'react'
import styled from 'styled-components'

const VSicon = ({bgColor, viewName, addNewView}) => {

    return (
        <div>
            <Container 
                bg={bgColor}
                onClick={() => addNewView(viewName)}
            >
                {viewName[0]}
            </Container>
            
        </div>
    )
}

export default VSicon

const Container = styled.div`
    background-color: gray;
    background-color: ${props => props.bg};
    height: 150px;
    width: 150px;
    margin: 20px;
    border-radius: 20px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;    
  text-align: center;
    line-height: 150px;
    color: white;
    cursor: pointer;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; /* Safari */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    transition: 0.1s ease-in-out;

    :hover {
        filter: brightness(110%);
    }

    :active {
        transform: scale(0.95);
        box-shadow: 0 4px 8px 0px rgb(0 0 0 / 5%) !important;

    }
`
