import React from 'react'
import {useState, useEffect} from 'react'
import "./ViewNotif.css"

const ViewNotif = ({note, update, className}) => {

    const [show, setShow] = useState("fadeIn");

    useEffect(() => {      
        setShow("fadeIn");
    }, [update])

    useEffect(() => {
        const timeId = setTimeout(() => {
          setShow("fadeOut")
        }, 1000)
    
        return () => {
          clearTimeout(timeId);
        }
      }, [update]);

    if (note === null) return null;

    return (
        <>
            <div className={`${className} ${show}`}>{note}</div>
        </>
    )
}

export default ViewNotif
