import styled from 'styled-components'
import React, {useState, useEffect} from 'react'
import "./NoteOverlay.css"

const NoteOverlay = ({show, updateTitle, updateBody, t,b}) => {

    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [overlay, setOverlay] = useState("NoteOverlay-closed")

    useEffect(() => {
        if (t !== "") setTitle(t);
        if (b !== "") setBody(b);

        if (show) setOverlay("NoteOverlay-open")
        else {
            setOverlay("NoteOverlay-closed");
            setTimeout(() => {
                setBody("");
                setTitle("");
            }, 100)
            
        }
    }, [show, t, b])

    useEffect(() => {
        updateTitle(title);
        updateBody(body)
    }, [title, body, updateBody, updateTitle])

    const updateText = (e, t) => {
        if (t === "t") setTitle(e.target.value);
        if (t === "b") setBody(e.target.value);
    }

    return (
        <>
        <Container
            className={`NoteOverlay ${overlay}`}
        >
            <TitleTextArea
                spellCheck={true}
                className={"NoteOverlay"}
                placeholder={"Title"}
                value={title}
                onChange={(e) => updateText(e, "t")}
            />
            <BodyTextArea 
                spellCheck={true}
                className={"NoteOverlay"}
                placeholder={"notes..."}
                value={body}
                onChange={(e) => updateText(e, "b")}
            />
        </Container>
        </>
    )
}

export default NoteOverlay

const Container = styled.div`
    width: 100%;
    height: 88%;
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0px rgb(200 200 200 / 15%) !important;
    background-color: #fffdf5;
    display: flex;
    flex-direction: column;
    align-items: center;
    `
const TitleTextArea = styled.textarea`
    border: none;
    overflow: auto;
    outline: none;
    background-color: #fffdf5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 0.1em solid #fff4cc;
    resize: none;
    width: 85%;
    margin-bottom: 15px;
    margin-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    color: #202020;
    height: 12%;
`
const BodyTextArea = styled.textarea`
    height: 80%;
    width: 85%;
    border: none;
    overflow-y: auto;
    outline: none;
    background-color: #fffdf5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 1em;
    margin-bottom: 10px;
    color: #202020;


`