import React, {useState, useEffect} from 'react' 
import "./QuickNote.css"
import {db} from "../fire";
import firebase from 'firebase/app'

const QuickNote = ({idx}) => {

    const [note, setNote] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);

    useEffect(() => {
        db.collection("userData")
        .doc(firebase.auth().currentUser.uid)
        .collection("appQuickNote")
        .doc(`appQuickNote${idx}`).get().then(snapshot => {
            if (snapshot.exists) {
                setNote(snapshot.data().note);
            }
            setDataUpdated(true);
        })
    }, [])

    const updateText = async e => {
        await setNote(e.target.value);
        
    }

    useEffect(() => {
        if (!dataUpdated) return;
        db.collection("userData")
        .doc(firebase.auth().currentUser.uid)
        .collection("appQuickNote")
        .doc(`appQuickNote${idx}`)
        .set({note}, {merge:true})
    }, [note])

    return (
        <div className="QN-container">
            <textarea 
                autoCorrect="true"
                autoComplete="on"
                autoCapitalize="words"
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder={"New note..."}
                className="QN-input">
            </textarea>
        </div>
    )
}

export default QuickNote
