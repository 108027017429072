import WeatherView from './WeatherView';
import NotesView from './NotesView';
import QuickNote from './QuickNote';
import ColorPicker from './ColorPicker';
import Pomodoro from './Pomodoro';
import Habit from './Habit';

import React from 'react';
import {WidthProvider, Responsive} from "react-grid-layout";
import "./Board.css";
import _ from "lodash";
import {getUser, saveData, saveLayout} from './DBHandler';
import {db} from "../fire";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import firebase from 'firebase/app'

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const largeYVal = 1000000;

export default class Board extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        layouts: {},
        items: [],
        counter: 0,
        editMode: false,
        toggleDone: props.toggleDone,
        uid: null,
        loaded: false,

      };
      this.onAddItem = this.onAddItem.bind(this);
    }

    async componentDidMount() {      

      if (localStorage.getItem("userNum") != null) {
        this.setState({
          uid: localStorage.getItem("userNum")
        });  
      } else {
        await this.setState({
          uid: getUser()
        }); 
      }

      if (this.props.first) {
        this.setState({loaded: true});
        return;
      };

      if (localStorage.getItem("counter") != null) {
        this.setState({counter: JSON.parse(localStorage.getItem("counter"))});
      } else {
        await db.collection("userData").doc(firebase.auth().currentUser.uid).get().then((snapshot) => {
          this.setState({counter: snapshot.data().counter});
        });
      }

      if (localStorage.getItem("items") != null) {
        this.setState({items: JSON.parse(localStorage.getItem("items"))});
      } else {
        await db.collection("userData").doc(firebase.auth().currentUser.uid).get().then((snapshot) => {
          this.setState({items: snapshot.data().items});
        });  
      }

      if (localStorage.getItem("layouts") != null) {
        this.setState({layouts: JSON.parse(localStorage.getItem("layouts"))});
      } else {
        await db.collection("userData").doc(firebase.auth().currentUser.uid).get().then((snapshot) => {
          this.setState({layouts: JSON.parse(snapshot.data().layouts)});
        }); 
      }

      this.setState({loaded: true});

    }

    editBoard(editSet) {
      this.setState({editMode: editSet});
    }

    saveToLocalStorage(key, toSave) {
      localStorage.setItem(key, toSave);
    }

  
    onLayoutChange(layout, layouts) {
    
      if (this.state.loaded === false) return;
      saveLayout(this.state.uid, layouts);
      saveData(this.state.uid, this.state.counter, "counter");
      saveData(this.state.uid, this.state.items, "items");
      this.setState({layouts:layouts});      
      this.saveToLocalStorage("items", JSON.stringify(this.state.items));
      this.saveToLocalStorage("counter", JSON.stringify(this.state.counter));
      this.saveToLocalStorage("layouts", JSON.stringify(this.state.layouts));
    }

    createElement(el) {
      const removeStyle = {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer"
      };
      return (
        <div key={el.i} data-grid={el}>
          {el.i.split(",")[0] === "weather" && <WeatherView idx={el.i.split(",")[3]}/>}
          {el.i.split(",")[0] === "quick note" && <QuickNote idx={el.i.split(",")[3]}/>}
          {el.i.split(",")[0] === "notes" && <NotesView idx={el.i.split(",")[3]}/>}
          {el.i.split(",")[0] === "color picker" && <ColorPicker idx={el.i.split(",")[3]}/>}
          {el.i.split(",")[0] === "pomodoro" && <Pomodoro idx={el.i.split(",")[3]}/>}
          {el.i.split(",")[0] === "habit" && <Habit idx={el.i.split(",")[3]}/>}

          {this.state.editMode &&
            <span
              className="remove-tile-btn"
              style={removeStyle}
              onClick={this.onRemoveItem.bind(this, el.i)}
            >
              x
            </span>
          }
        </div>
      );
    }

    onAddItem(nv) {
      this.setState({
        // Add a new item. It must have a unique key!
        items: this.state.items.concat({
          i: `${nv},${this.state.counter.toString()}`,
          x: (this.state.items.length * 2) % (this.state.cols || 8),
          y: largeYVal, // puts it at the bottom
          w: nv[1],
          h: nv[2],
        }),
        // Increment the counter to ensure key is always unique.
        counter: this.state.counter + 1
      });
    }

    onRemoveItem(i) {
      this.setState({ items: _.reject(this.state.items, { i: i }) });
      if (this.state.layouts.length === 1) {
        this.state.toggleDone();
        this.editBoard(false);
      }

    }
    
  
    render() {
      return (
        <>
        {!this.state.loaded ? (
          <div className="loading-container">
            <Loader type="Grid" color="rgba(91, 192, 190, 1)" height={40} width={40} />
          </div>
        ) : (<div className="board-container">
            <div className="board-inner-container">
            <ResponsiveReactGridLayout
                width={700}
                className="layout"
                margin={[20, 20]}
                cols={{ xl: 12, lg: 10, md: 6, sm: 4, xs: 4, xxs: 2 }}
                isResizable={false}
                rowHeight={30}
                isDraggable={this.state.editMode}
                measureBeforeMount={true}
                layouts={this.state.layouts}
                onLayoutChange={(layout, layouts) =>this.onLayoutChange(layout, layouts)}
            >
                {_.map(this.state.items, el => this.createElement(el))}

            </ResponsiveReactGridLayout>
            </div>
        </div>)}
        </>
      );
    }
  }
  
