import React, {useState, useEffect} from 'react'
import "./Settings.css";
import firebase from 'firebase/app'
import fire, {provider, db} from "../fire";
import ViewNotif from "./ViewNotif";

const Settings = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pword, setPword] = useState("");
    const [nameInput, setNameInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [pwordInput, setPwordInput] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [lock, setLock] = useState(true);
    const [noteUpdate, setNoteUpdate] = useState(false);

    const update = async (type, inpType) => {
        await db.collection("userData").doc(firebase.auth().currentUser.uid).set({[type]:inpType}, {merge:true});
        await db.collection("userData").doc(firebase.auth().currentUser.uid).get().then(snapshot => {
            if (snapshot.data()[type] != undefined && type === "name") setName(snapshot.data()[type]);
        })
        if (type === "name") setNameInput("");
        if (type === "email") setEmailInput("");
        setNoteUpdate(!noteUpdate);
    }

    const updateEmail = () => {

        firebase.auth().fetchSignInMethodsForEmail(emailInput).then(snapshot => {
            if (snapshot.length > 0) {
                console.log(snapshot);
                setErrorMsg("Account already exists with this email address.");
                setEmailInput("");
                return;
            }
        })

        firebase.auth().currentUser.updateEmail(emailInput).then(() => {
            update("email", emailInput);
        }).catch((error) => {
            if (error.code === "auth/email-already-in-use") setErrorMsg("Account already exists with this email address.");
            else if (error.code === "auth/invalid-email") setErrorMsg("Invalid email address.");
            else if (error.code === "auth/requires-recent-login") setErrorMsg("Session timout. Log out and back in again.");
            else console.log(error);
            setEmailInput("");
        })
    }

    const updatePassword = () => {
        firebase.auth().currentUser.updatePassword(pwordInput).then(() => {
            setNoteUpdate(!noteUpdate);
        }).catch((error) => {
            if (error.code === "auth/weak-password") setErrorMsg("Password must be at least 6 characters");
            else console.log(error);
        })
        setPwordInput("");
    }

    const reAuth = () => {
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email, 
            pwordInput
        );
        
        user.reauthenticateWithCredential(credential).then(() => {
            setLock(false);
        }).catch(error => {
            if (error.code === "auth/wrong-password") setErrorMsg("Incorrect password");
            else if (error.code === "auth/too-many-requests") setErrorMsg("Too many attempts. Please try again later.");
            else setErrorMsg(error.message);
        }); 
        setPwordInput("");
        setTimeout(() => {
            setLock(true);
        }, 300000);
    }

    useEffect(() => {
        db.collection("userData").doc(firebase.auth().currentUser.uid).get().then(snapshot => {
            if (snapshot.data() != undefined && snapshot.data().name != undefined) setName(snapshot.data().name);
        })
    }, [])

    useEffect(() => {
        if  (errorMsg !== "") {
            setTimeout(() => {
                setErrorMsg("");
            }, 2000);
        }
    }, [errorMsg])



    return (
        <div className="Settings-container">
            <div className="Settings-box">
                <div className="Settings-box-interior">
                    <div className="Settings-box-title">
                        Account Details
                    </div> 
                    <form className="Settings-account-box">
                        <ViewNotif note="Updated" update={noteUpdate} className="Settings-vn"/>
                        <div className="Settings-acc-err-msg">{errorMsg}</div>
                        {lock && <div className="Settings-relogin-box">
                            Please sign in to continue
                            <input 
                                    type="search" 
                                    id="Settings-pword"
                                    className="Settings-name-input-relog" 
                                    autoComplete="new-password"
                                    onChange={(e) => setPwordInput(e.target.value)}
                                    value={pwordInput}

                            />
                            <div className="Settings-signin-confirm" onClick={reAuth}>confirm</div>
                        </div>}
                        Name
                        <div className="Settings-input-container">
                            <input 
                                type="search" 
                                className="Settings-name-input" 
                                placeholder={name}
                                autoComplete="new-password"
                                value={nameInput}
                                onChange={(e) => setNameInput(e.target.value)}
                            />
                            {!lock && nameInput && <div onClick={() => setNameInput("")} className="Settings-input-x">X</div>}
                            {!lock && nameInput && <div onClick={() => update("name", nameInput)} className="Settings-input-btn">save</div>}
                        </div>
                        
                        Email
                        <div className="Settings-input-container">
                            {!lock && <input 
                                type="search" 
                                className="Settings-name-input" 
                                placeholder={firebase.auth().currentUser.email}
                                autoComplete="new-password"
                                onChange={(e) => setEmailInput(e.target.value)}
                                value={emailInput}

                            />}
                            {!lock && emailInput && <div onClick={() => setEmailInput("")} className="Settings-input-x">X</div>}
                            {!lock && emailInput && <div onClick={updateEmail} className="Settings-input-btn">save</div>}
                        </div>

                        Password
                        <div className="Settings-input-container">
                        {!lock && <input 
                            type="search" 
                            className="Settings-name-input" 
                            id="Settings-pword"
                            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                            autoComplete="new-password"
                            onChange={(e) => setPwordInput(e.target.value)}
                            value={pwordInput}
                        />}
                        {!lock && pwordInput && <div onClick={() => setPwordInput("")} className="Settings-input-x">X</div>}
                        {!lock && pwordInput && <div onClick={updatePassword} className="Settings-input-btn">save</div>}
                        </div>

                    </form>
                </div>
            </div>
            {!lock && <div className="Settings-box">
                <div className="Settings-box-interior">
                     <div className="Settings-box-title"
                        onClick={() => alert("Under construction. Check back soon!")}
                     >
                        Delete Account
                    </div>
                </div>
            </div>}

            <div className="Settings-box">
                <div className="Settings-box-interior">
                    <div className="Settings-box-title"
                        onClick={() => alert("Under construction. Check back soon!")}
                    >
                        Feedback & Bug Report
                    </div> 
                </div>
            </div>

            <div className="Settings-box">
                <div className="Settings-box-interior">
                    <div className="Settings-box-title"
                        onClick={() => alert("Under construction. Check back soon!")}
                    >
                        Privacy & Terms 
                    </div> 
                </div>
            </div>
            <div className="Settings-box">
                <div className="Settings-box-interior">
                    <div className="Settings-box-title">
                        <a href="https://www.buymeacoffee.com/dylankroft" 
                        >Buy me a coffee</a>
                    </div> 
                </div>
            </div>

        </div>
    )
}

export default Settings
