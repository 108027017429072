import React, {useEffect, useState} from 'react';
import "./ViewSelector.css";
import {IoIosArrowBack} from 'react-icons/io';
import { IconContext } from "react-icons";
import VSicon from './VSicon';
import WallpaperRow from "./WallpaperRow";
import Settings from "./Settings";

const ViewSelector = ({open, setViewSelector, appAddView, type}) => {

    const [fade, setFade] = useState("VS-container");

    useEffect(() => {
        if (open) {
            setFade("VS-container-fadein");
        } else {
            setFade("VS-container");
        }
    }, [open]);

    const addNewView = v => {
        appAddView(v);
    }

    return (
        <div>
            <div className={fade}>
                <div 
                    onClick={setViewSelector}
                    className={"VS-remove"}>
                        <IconContext.Provider value={{className: "VS-icon-style"}}>
                            <IoIosArrowBack/>
                        </IconContext.Provider>
                </div>
                {appAddView && 
                    <>
                    <div className={"VS-list-container"}>

                        <VSicon 
                            bgColor={"#0093e9"} 
                            viewName={["weather", 2, 6]}
                            addNewView={addNewView}
                        />
                        <VSicon 
                            bgColor={"#ffe175"} 
                            viewName={["notes", 3, 8]}
                            addNewView={addNewView}
                        />

                        <VSicon 
                            bgColor={"#cfb997"} 
                            viewName={["quick note", 2, 6]}
                            addNewView={addNewView}
                        />

                        <VSicon 
                            bgColor={"#800080"} 
                            viewName={["color picker", 2, 6]}
                            addNewView={addNewView}
                        />

                        <VSicon 
                            bgColor={"beige"} 
                            viewName={["pomodoro", 2, 6]}
                            addNewView={addNewView}
                        />

                        <VSicon 
                            bgColor={"#e2e5e9"} 
                            viewName={["habit", 2, 8]}
                            addNewView={addNewView}
                        />
                        </div>
                    </>
                }

                {!appAddView && type === "wallpaper" &&
                    <div className={"VS-list-container"}>
                        
                        <WallpaperRow
                            bgColor={"background-color: #FAACA8;background-image: linear-gradient(-225deg, #e3fdf5 0%, #ffe6fa 100%);"}
                            />

                        <WallpaperRow
                            bgColor={"background-color: #FAACA8;background-image: linear-gradient(135deg, #FAACA8 0%, #DDD6F3 100%);"}
                        />  
                        
                        <WallpaperRow
                            bgColor={"background-color: #8BC6EC; background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);"}
                        />

                        <WallpaperRow
                            bgColor={"background-color: #0093E9; background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);"}
                        />
                        
                        <WallpaperRow
                            bgColor={"background-color: #16a085;background-image: linear-gradient(135deg, #16a085 0%, #12d87f 100%);"}
                        />

                        <WallpaperRow
                            bgColor={"background-color: #FBAB7E;background-image: linear-gradient(135deg, #FBAB7E 0%, #F7CE68 100%);"}
                        />

                        <WallpaperRow
                            bgColor={"background: #232526; background: -webkit-linear-gradient(to right, #232526, #414345); background: linear-gradient(to right, #232526, #414345);"}
                        />
                    </div>    
                }

                {!appAddView && type === "settings" &&
                    <div className={"VS-list-container"}>
                        <Settings />
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewSelector
