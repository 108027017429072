import React, {useEffect, useState, useRef} from 'react'
import "./Pomodoro.css"
import {RiSkipForwardLine, RiPauseFill, RiRestartLine, RiPlayLine} from "react-icons/ri"
import { IconContext } from "react-icons";
import Countdown, {zeroPad} from 'react-countdown';
import useSound from 'use-sound';
import Audio from "./audio/alarmsound.mp3"


const Pomodoro = () => {

    const ref= useRef();
    const [dim, setDim] = useState(0);
    const [counting, setCounting] = useState(false);
    const [doneAmt, setDoneAmt]  = useState(0);
    const [time, setTime] = useState(1500000);
    const [style, setStyle] = useState("focus");
    const [date, setDate] = useState(Date.now() + time);
    const [timeUp, setTimeUp] = useState(true);

    const [play, { stop }] = useSound(Audio);


    useEffect(() => {
        updateDim();
        window.addEventListener("resize", updateDim);
        return () => window.removeEventListener("resize", updateDim);
    })

    useEffect(() => {
        setCounting(false);
        handleReset();
    }, [time])

    const updateDim = () => {
        let h = document.querySelector(".P-container").offsetHeight * 0.87;
        let w = document.querySelector(".P-container").offsetWidth * 0.87;
        setDim(Math.min(h, w));
    }

    const handleStart = (e) => {
        ref.current && ref.current?.start();
        setCounting(true);
    }

    const handlePause = (e) => {
        ref.current && ref.current?.pause();
        setCounting(false);
    }

    const handleReset = () => {
        setDate(Date.now() + time);
        setCounting(false);
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        setDoneAmt(100 - (minutes * 60 + seconds)/(time/1000)*100);
        return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }

    const switchMode = () => {
        if (time == 1500000) {
            setTime(300000);
            setStyle("fun");
        }
        else {
            setTime(1500000);
            setStyle("focus");
        }
    }
    
    return (

        <div className={`P-container ${style}`}>
            
            
            <div className={`P-clock-bg ${style}`} style={{height: dim, width: dim}}>
            <div className={`P-clock-overlay ${style}`} style={{height: dim, width: dim, "--value":`${doneAmt}%`}}/>
                <div className={`P-clock-cover ${style}`} style={{height: dim*0.8, width: dim*0.8}}>
                    <div className="P-inner-container">
                        <div className="P-time-container">
                            <Countdown
                                ref={ref} 
                                autoStart={false}
                                date={date}
                                key={date}
                                onPause={handlePause}
                                renderer={renderer}
                                onComplete={() => {
                                    play(); 
                                    switchMode()
                                }}
                            />    
                        </div>
                        <div className="P-time-settings">

                            <IconContext.Provider value={{className: `P-next-icon ${style}`}}>
                                <RiRestartLine onClick={handleReset}/>
                            </IconContext.Provider>

                            <IconContext.Provider value={{className: `P-next-icon ${style}`}}>
                                {!counting ? 
                                    <RiPlayLine onClick={handleStart}/> :
                                    <RiPauseFill onClick={handlePause}/>
                                }
                            </IconContext.Provider>

                            <IconContext.Provider value={{className: `P-next-icon ${style}`}}>
                                <RiSkipForwardLine onClick={switchMode}/>
                            </IconContext.Provider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pomodoro
