import React from 'react'
import styled from 'styled-components'
import "./NoteRow.css"

const noteRow = ({title, body, select, deleteNote}) => {

    const handleRemove = e => {
        e.stopPropagation();
        deleteNote();
    }

    return (
        <>
        <Row 
            onClick={select}
            className={"NoteRow-row"}
        >
        <Remove
            className={"NoteRow-remove"}
            onClick={handleRemove}
        >X</Remove>

            <Title>
                {title}
            </Title>
            <Subtitle>
                {body}
            </Subtitle>

            <Bottom />
        </Row>
        </>
    )
}

export default noteRow

const Row = styled.div`
    background-color: #fff5d1;
    width: 100%;
    height: 25%;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1px;

    :hover {
        background-color: #fff9e6;
        cursor: pointer;
    }
`

const Bottom = styled.div`
    background-color: #ffedad;
    width: 100%;
    border-bottom: 2px dashed #fff4cc;
    align-self: center;
    margin-top: 6px;
`

const Title = styled.div`
    height: 100%;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-right: 15px;
    color: #202020;
    font-size: 14px;
    line-height: 24px;
    overflow: auto;
`


const Subtitle = styled.div`
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    color: #686868;
    font-size: 0.75em;
    overflow-x: auto;
    padding-bottom: 2px;
`

const Remove = styled.div`
    position: absolute;
    margin-top: 10px;
    width: 15px;
    height: 15px;
    right: 40px;
    text-align: center;
    line-height: 15px;
    font-size: 10px;
    border-radius: 100px;
    background-color: rgba(255, 225, 117, 1);
    color: white;
    display: none;

    :hover {
        filter: brightness(105%);
    }
`
