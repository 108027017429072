import React, {useState, useEffect, useRef} from 'react'
import "./WeatherView.css"
import {GoSearch} from 'react-icons/go';
import { IconContext } from "react-icons";
import {WiRain, WiCloudy, WiThunderstorm, WiNightClear, WiDaySunny, WiFog, WiRainMix, WiSnow} from 'weather-icons-react';
import { RiSettingsLine } from 'react-icons/ri';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import _ from "lodash";
import ViewNotif from './ViewNotif';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {db} from "../fire";
import firebase from 'firebase/app'

const WeatherView = ({idx}) => {

    const [query, setQuery] = useState('');
    const [weather, setWeather] = useState(null);
    const [tileStyle, setTileStyle] = useState("");
    const [flipIconColor, setFlipIconColor] = useState("#0093e9");
    const [weatherIcon, setWeatherIcon] = useState(null);
    const [notification, setNotification] = useState(null);
    const [notifUpdate, setNotifUpdate] = useState(false);
    const [coords, setCoords] = useState({lat: null, lng: null});
    const [apiKey, setApiKey] = useState("");
    const ref = useRef();

    const api = {
        base: "https://api.openweathermap.org/data/2.5/"
    }

    useEffect(() => {
        db.collection("userData")
        .doc("DO NOT DELETE").get().then(snapshot => {
            setApiKey(snapshot.data().weather);
        })

        db.collection("userData")
        .doc(firebase.auth().currentUser.uid)
        .collection("appWeather")
        .doc(`appWeather${idx}`).get().then(snapshot => {
            if (snapshot.exists) {
                setCoords(snapshot.data().coords);
                search();
            }
        })
    },[])

    const search = () => {      
        loadWeatherData();
            setQuery("");
    }

    useEffect(() => {
        if (coords.lat == null || coords.lng == null) return;
        search();
    }, [coords])
  

    const loadWeatherData = () => {
     
        fetch(`${api.base}weather?lat=${coords.lat}&lon=${coords.lng}&appid=${apiKey}`)
        .then(res => res.json())
        .then(result => {
            if (result.name !== undefined) {
                setWeather(result);
                updateViewStyle(result);
                setNotification("Updated");
                setNotifUpdate(!notifUpdate);
                dbUpdateWeather();
            } else {
                setNotification("Updated");
                setNotifUpdate(!notifUpdate);
            }        
        });
    }
    
    const dbUpdateWeather = () => {
        db.collection("userData")
        .doc(firebase.auth().currentUser.uid)
        .collection("appWeather")
        .doc(`appWeather${idx}`)
        .set({coords}, {merge: true});
    }

    const updateViewStyle = (data) => {
        if (data == null) return;

        let weatherType = data.weather[0].main.toLowerCase();
        let weatherId = parseInt(data.weather[0].id.toString()[0]);
        if (data.dt >= data.sys.sunset) {
            setTileStyle("night");
            setFlipIconColor("#2b5876");
            if (!_.isEmpty(_.intersection(["rain", "thunderstorm", "fog", "snow", "mist", "drizzle", "smoke", "ash", "haze", "tornado", "dust"], [weatherType]))) {
                setTileStyle("rain-night");
                setFlipIconColor("#09203f");

            };
        } else {
            setTileStyle("day");
            setFlipIconColor("#0093e9");
            if (!_.isEmpty(_.intersection(["rain", "thunderstorm", "fog", "snow", "mist", "drizzle", "smoke", "ash", "haze", "tornado", "dust"], [weatherType]))) {
                setTileStyle("rain-day");
                setFlipIconColor("#cfcfcf");
            };
        }

        if (weatherId === 5) setWeatherIcon(<WiRain className={"weather-icon"}/>);
        if (weatherId === 2) setWeatherIcon(<WiThunderstorm className={"weather-icon"}/>); 
        if (weatherId === 7) setWeatherIcon(<WiFog className={"weather-icon"}/>); 
        if (weatherId === 3) setWeatherIcon(<WiRainMix className={"weather-icon"}/>); 
        if (weatherId === 6) setWeatherIcon(<WiSnow className={"weather-icon"}/>); 
        if (weatherId === 8 && data.dt >= data.sys.sunset) setWeatherIcon(<WiNightClear className={"weather-icon"}/>); 
        if (weatherId === 8 && data.dt < data.sys.sunset) setWeatherIcon(<WiDaySunny className={"weather-icon"}/>); 
        if (weatherType.includes("clouds")) setWeatherIcon(<WiCloudy className={"weather-icon"}/>); 


    }

    const dateBuilder = (d) => {
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let day = days[d.getDay()];
        let date = d.getDate();
        let month = months[d.getMonth()];
        let year = d.getFullYear();
        return `${day} ${date} ${month} ${year}`
    }

    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setCoords(latLng);
    }

        return (
            <>
                <Flippy
                    flipOnHover={false} // default false
                    flipOnClick={false} // default false
                    flipDirection="horizontal" // horizontal or vertical
                    ref={ref} // to use toggle method like ref.curret.toggle()
                >

                    <FrontSide>
                        <div className="flip-btn-box">
                            <button className="flip-btn" onClick={() => { ref.current.toggle(); }}>
                                <IconContext.Provider value={{ color: flipIconColor, className: "icon-style"}}>
                                    <RiSettingsLine/>
                                </IconContext.Provider>
                            </button>
                    </div>

                    <div>
                        <div className={`weather-tile ${tileStyle}`}>
                            {weather != null ? <div className="weather-front-tile">
                                <div className="weather-data-container">
                                    
                                    <div className="weather-info-box">

                                        <div className="weather-icon-container">
                                            {weatherIcon}
                                        </div>
                                        
                                        <div className="weather-temp-txt">
                                            {weather == null ? "" :  Math.round(weather.main.temp - 273.15) + "°C"}
                                        </div>
                                        <div className="weather-type-txt">
                                                {weather == null ? "" : weather.weather[0].main}
                                        </div>
                                    </div>

                                    <div className="weather-location-box">
                                        <div className="weather-location-txt">{weather == null ? "" : `${weather.name.substring(0, 20)}` + ","} {weather == null ? "" :  weather.sys.country}</div>
                                        <div className="weather-date-txt">{dateBuilder(new Date())}</div>
                                    </div>
                                </div>
                                
                                {true && 
                                    <div className="weather-extra-data"></div>
                                }
                            </div> : <div className="set-weather-indication"><div>Select a location to display weather</div></div>}
                        </div>
                    </div>
                    </FrontSide>

                    <BackSide>
                        <ViewNotif className={"WV-viewnotif"} update={notifUpdate} note={notification}/>
                            <div className="flip-btn-box">
                                <div className="flip-btn" onClick={() => { ref.current.toggle(); }}>
                                    <IconContext.Provider value={{ color: flipIconColor, className: "icon-style"}}>
                                        <RiSettingsLine/>
                                    </IconContext.Provider>
                                </div>
                        </div>

                        <div className={`weather-tile ${tileStyle}`}>
                              
                            <div 
                                className="weather-search-box"
                            >
                                <PlacesAutocomplete
                                    value={query}
                                    onChange={setQuery}
                                    onSelect={e => handleSelect(e)}
                                    searchOptions={{types: ['(regions)']}}
                                    >
                                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                        <div>
                                            <input    
                                                className="weather-search-bar" 
                                                {...getInputProps({placeholder:"Location"})}
                                            />
                                            <div className="weather-search-dropdown">
                                                {loading ? <div className="weather-search-row" style={{padding: "7.5px 10px 7.5px 10px"}}>...loading</div> : null}
                                                {suggestions.map((suggestion) => {
                                                    const style = {
                                                        backgroundColor: suggestion.active ? "#134074" : "white",
                                                        color: suggestion.active ? "white" : "#525252",
                                                        padding: "7.5px 10px 7.5px 10px",
                                                        cursor: "pointer",
                                                        zIndex: suggestion.active ? "34345435" : "4543534534",
                                                    }
                                                    return <div className="weather-search-row" key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style})}>{suggestion.description}</div>;
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete> 
                                    <button 
                                        className="weather-search-btn"
                                    >
                                        <IconContext.Provider value={{ color: "#525252"}}>
                                            <GoSearch />
                                        </IconContext.Provider>
                                    </button>
                            </div>
                        </div>

                    </BackSide>
            
                </Flippy>
            </>

        )
    
}

export default WeatherView