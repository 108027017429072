import './App.css';
import Board from './components/Board.js';
import { slide as Menu } from 'react-burger-menu';
import React, { useRef, useState, useEffect } from 'react';
import ViewSelector from "./components/ViewSelector";
import fire, {provider, db} from "./fire";
import Login from './Login';
import firebase from 'firebase/app'
import {IoLogOutOutline} from "react-icons/io5"
import { IconContext } from "react-icons";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {

  const [user, setUser] = useState("");
  const [email, setEmail] =  useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [hasAcc, setHasAcc]= useState(true);
  const [name, setName] = useState("");
  const [emailConf, setEmailConf] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [vSelectorOpen, setVSelectorOpen] = useState(false);
  const [wSelectorOpen, setWSelectorOpen] = useState(false);
  const [sSelectorOpen, setSSelectorOpen] = useState(false);

  const [newView, setNewView] = useState(null);

  const [loaded, setLoaded] = useState(false); 
  const [firstTime, setFirstTime] = useState(false);
  const myref = useRef();

  const height = (window.screen.height - (window.outerHeight - window.innerHeight));


  /*****************************************************************************/

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setWallPaper();
        setEmailConf(firebase.auth().currentUser.emailVerified);
      };
      setLoaded(true);
    });
  }, [])

  useEffect(() => {
    setWallPaper();
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
      setLoaded(true);
    }
  }, []);


  /*****************************************************************************/
  const clearInputs = () => {
    setEmail("");
    setPassword("");
  }

  const clearErrors = () => {
    setErrorMsg("");
  }

  const handleLogin = () => {
    clearErrors();
    fire 
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        localStorage.setItem("user", firebase.User.getIdToken());
        localStorage.setItem("userNum", firebase.getUser());
      })
      .catch(err => {
        switch(err.code) {
          case 'auth/invalid-email':
            setErrorMsg("Invalid email address");
            break;
          case 'auth/wrong-password':
            setErrorMsg("Invalid username or password");
            break;
          case 'auth/user-not-found':
            setErrorMsg("Invalid username or password");
            break;
          default:
            console.log(err.code);
            break;
        }
        
        clearInputs();
        
    });
  };
  
  const googleSignIn = () => {
    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
          localStorage.setItem("userNum", firebase.getUser());
          db.collection("userData").doc(firebase.auth().currentUser.uid).set({
            email: firebase.auth().currentUser.email,
            userName: "Test Account!",
            counter: 0,
            items: [],
            layouts: "{}"
          }, {merge: true});
        setHasAcc(true);
        if (
          firebase.auth().currentUser.metadata.creationTime ===
          firebase.auth().currentUser.metadata.lastSignInTime
        ) {
            setFirstTime(true);
            console.log("HIIII")
        }
      }).catch((error) => {
        console.log(error)
      });
  }

  const handleSignup = () => {
    setFirstTime(true);
    clearErrors();
    fire 
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(cred => {
        let doc = db.collection("userData").doc(cred.user.uid);
        doc.set({
          email: cred.user.email,
          userName: "Test Account!",
          counter: 0,
          items: [],
          layouts: "{}"
        });
      setHasAcc(!hasAcc);
      firebase.auth().currentUser.sendEmailVerification();
      })
      .catch(err => {
        console.log(err.message)
        switch(err.code) {
          case 'auth/email-already-in-use':
            setErrorMsg("Account with this email already exists");
            break;
          case 'auth/invalid-email':
            setErrorMsg("Invalid email address");
            break;
          case 'auth/weak-password':
            setErrorMsg("Password should be at least 6 characters long");
            break;
          default:
            console.log(err.code);
            break;
        }
        clearInputs();
    });
  };

  const handleLogout = () => {
    fire.auth().signOut();
    document.querySelector("html").setAttribute("style", "background-color: #FAACA8;background-image: linear-gradient(-225deg, #e3fdf5 0%, #ffe6fa 100%);");
    setUser({});
    setPassword("");
    localStorage.clear();
  }

  const authListener = () => {
    fire.auth().onAuthStateChanged(user => {
      if (user) {
        clearInputs();
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, [])

  /*****************************************************************************/

  const setWallPaper = () => {
    if (localStorage.getItem("wallpaper") != null) {
      document.querySelector("html").setAttribute("style", JSON.parse(localStorage.getItem("wallpaper")).background);
      return;
    }
    if (!user) return;
    
    db.collection("userData").doc(firebase.auth().currentUser.uid).get().then((snapshot) => {
      if (snapshot.data().background !== undefined) {
        document.querySelector("html").setAttribute("style", snapshot.data().background);
      }
    })
  }
  /*****************************************************************************/

  useEffect(() => {
    if (!firebase.auth().currentUser) return;
    db.collection("userData").doc(firebase.auth().currentUser.uid).get().then((snapshot) => {
      if (snapshot.data() == undefined) return;
      let name = snapshot.data().name;
      if (name != undefined) setName(name);
    }) 
  })

  useEffect(() => {
    let bmOverlay = document.querySelector(".bm-overlay");
    if (bmOverlay == null) return;
    
    bmOverlay.addEventListener("click", () => {
      setVSelectorOpen(false);
      setWSelectorOpen(false);
      setSSelectorOpen(false);
    });

  }, [vSelectorOpen,sSelectorOpen, wSelectorOpen])

  useEffect(() => {
    if (newView != null) {
      addView(newView);
      setNewView(null);
    }
  }, [vSelectorOpen, newView])
  
  const addView = (nv) => {
    if(myref.current) {
      myref.current.onAddItem(nv);
    }
  }

  const editBoard = (mode) => {
    if(myref.current) {
      myref.current.editBoard(mode);
    }
    setEditMode(mode);
  }

  /*****************************************************************************/


  return (
    <>
    {!loaded &&
      <div className="loading-container">
        <Loader type="Grid" color="rgba(91, 192, 190, 1)" height={40} width={40} />
      </div>
    }

    {loaded && !user &&
      <Login 
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handleLogin={handleLogin}
      handleSignup={handleSignup}
      hasAcc={hasAcc}
      setHasAcc={setHasAcc}
      errorMsg={errorMsg}
      googleSignIn={googleSignIn}
      />
    }

    {!emailConf && user && loaded &&
      <div className="App-conf-email-outside-container" style={{height: `${height}px`}}>
        <div className="App-conf-email">
          <div className="App-conf-container">morning.</div>
          <div className="conf-text">Welcome to Morning! Please confirm your email to continue.</div>
          <div className="conf-text-back" onClick={handleLogout}>Use a different account</div>

        </div>
      </div>
    }

    {emailConf && user && loaded &&
      <>
        <ViewSelector 
          setViewSelector={() => setVSelectorOpen(false)} 
          open={vSelectorOpen}
          appAddView={view => setNewView(view)}
        />

        <ViewSelector 
          setViewSelector={() => setWSelectorOpen(false)} 
          open={wSelectorOpen}
          appAddView={null}
          type="wallpaper"
        />

        <ViewSelector 
          setViewSelector={() => setSSelectorOpen(false)} 
          open={sSelectorOpen}
          appAddView={null}
          type="settings"
        />

        <Menu 
          disableAutoFocus
          isOpen={false}><div className={"menu-info-container"}>
            <div className="menu-info-text">
              <div className="menu-hello">Hello,</div>
              <div className="menu-name">{name}</div>
            </div>
          </div>
            
            <div className={"menu-btn"} onClick={() => editBoard(true)}>Edit Board</div>
            <div className={"menu-btn"} onClick={() => setVSelectorOpen(true)}>Add View</div>
            <div className={"menu-btn"} onClick={() => setWSelectorOpen(true)}>Set Wallpaper</div>
            <div className={"menu-btn"} onClick={() => setSSelectorOpen(true)}>Settings</div>
            <div className={"menu-buffer"}></div>
            <div className={"menu-btn"} id="app-logout" onClick={handleLogout}>
              <IconContext.Provider value={{ className: "logout-icon-style"}}>
                <IoLogOutOutline/>
              </IconContext.Provider>
              Log Out
              </div>

        </Menu>
        <div className={"menu-bar"}>
          {editMode  && 
            <div className={"edit-highlight"} onClick={() => editBoard(false)}>
              Finished Editing
            </div>
           
          }
        </div>
        {true && <Board 
          first={firstTime}
          ref={myref} 
          toggleDone={() => setEditMode(false)}
          addedView={newView}
        />}
      </>
    }
    </>
  );
}

export default App;
