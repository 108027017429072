import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyA7q-690Pj3g0eLxyam_uG2In70VBCFNwM",
    authDomain: "now-app-b65a8.firebaseapp.com",
    projectId: "now-app-b65a8",
    storageBucket: "now-app-b65a8.appspot.com",
    messagingSenderId: "1009205641140",
    appId: "1:1009205641140:web:2e3b721bacefa5c669bce1",
    measurementId: "G-X0YNLR41BX"
  };
  // Initialize Firebase
  
  const fire = firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();
  db.settings({ timeStampsInSnapshots: true})
  var provider = new firebase.auth.GoogleAuthProvider();

  export {provider, db};
  export default fire;