import React from 'react'
import styled from 'styled-components'
import fire, {provider, db} from "../fire";
import firebase from 'firebase/app'

const WallpaperRow = ({bgColor}) => {

    const changeWP = async () => {
        let wp = document.querySelector("html");
        wp.setAttribute("style", bgColor);
        let id = await firebase.auth().currentUser.uid;
        db.collection("userData").doc(id).set({background: bgColor}, {merge:true});
        localStorage.setItem("wallpaper", JSON.stringify({background: bgColor}))
    }

    return (
        <>
            <Container
                bg={bgColor}
                onClick={changeWP}
            >
            </Container>
        </>
    )
}

export default WallpaperRow

const Container = styled.div`
    background-color: white;
    ${props => props.bg};
    height: 150px;
    min-height: 150px;
    width: 250px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 150px;
    color: white;
    cursor: pointer;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    transition: 0.1s ease-in-out;

    :hover {
        filter: brightness(110%);
    }

    :active {
        transform: scale(0.95);
        box-shadow: 0 4px 8px 0px rgb(0 0 0 / 5%) !important;
    }
`

const X = styled.html`
    background-color: white !!important;
`