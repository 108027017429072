import React from 'react'
import {db} from "../fire";
import firebase from 'firebase/app'

const DBHandler = () => {
    return (
        <div>
            
        </div>
    )
}

function getUser() {
    let currentUser = firebase.auth().currentUser.uid;
    return currentUser;
}

function saveData(uid, data, field) {
    if (uid === null || uid === undefined || data === null) return;
    db.collection("userData").doc(uid).set({[field]: data}, {merge: true});
}


function saveLayout(uid, data) {
    if (uid === null || uid === undefined) return;

        db.collection("userData").doc(uid).update({
            "layouts": JSON.stringify(data),
        })
}

export {getUser, saveData, saveLayout}
export default DBHandler
